<template>
  <div class="image-manage-fy">
    <el-dialog title="查看图片" :close-on-click-modal="false"  :modal-append-to-body='false' :visible.sync="visible" width="670px" center>
      <div class="imageArea">
        <div placement="right-start" width="300" height="300" trigger="hover" popper-class="popImg" v-for="(item, index) in imgList" :key="index">
          <!-- <al-upload-card :fileData="imgList" :format="'img'" :fileUrl="$http.adornUrl('/upload/img')" :businessType="2000" :type="'driver'" :limit="2" @getFileData="getFileData" @handlePictureCardPreview="handlePictureCardPreview"></al-upload-card> -->
          <!-- <img :src="item.url">
          <span  slot="reference" class="imageItem" @click="enlargeImage(item.url)">
            <img :src="item.url" alt="">
          </span> -->
        </div>
        <div v-if="imgList.length">
            <al-upload-card :fileData="imgList" :format="'img'" :fileUrl="$http.adornUrl('/upload/img')" :businessType="businessType" :type="'driver'" :limit="limit"   @getFileData="getFileData" @handlePictureCardPreview="handlePictureCardPreview"></al-upload-card>
        </div>
        <div v-if="!imgList.length" class="noData">
          <al-upload-card :fileData="imgList" :format="'img'" :fileUrl="$http.adornUrl('/upload/img')" :businessType="businessType" :type="'driver'" :limit="limit" @getFileData="getFileData" @handlePictureCardPreview="handlePictureCardPreview"></al-upload-card>
          <!-- 暂无数据 -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">提 交</el-button>
        <el-button type="danger" @click="visible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <big-image ref="bigImg"></big-image>
  </div>
</template>
<script>
import bigImage from '@/views/commonPop/bigImage'
export default {
  components: {
    bigImage
  },
  props: ['limit'],
  data () {
    return {
      visible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      enclosureInfoList: [],
      businessId: '',
      businessType: '',
      imgList: []
    }
  },
  mounted () {
    this.limit = this.limit || 1
  },
  methods: {
    getFileData (data) {
      this.enclosureInfoList = data.fileList
    },
    handlePictureCardPreview (data) {
      this.$refs.bigImg.init(data.url)
    },
    init (id, typeList) {
      this.visible = true
      this.imgList = []
      this.businessId = id
      this.businessType = typeList[0]
      this.$http({
        url: this.$http.adornUrl('/enclosure/findListByTypeList'),
        method: 'POST',
        data: this.$http.adornParams({
          'businessId': id,
          'businessTypeList': typeList
        })
      }).then(({ data }) => {
        this.imgList = data
        // this.imgList = [{
        //   url: 'http://47.104.187.223/ydw/tms/annex/3b7eafb1b5454d1c97ecbcdc3486f05d.jpg'
        // }]
      })
    },
    submit () {
      this.$http({
        url: this.$http.adornUrl('/enclosure/save'),
        method: 'POST',
        data: this.$http.adornParams( {
          enclosureInfoList: this.enclosureInfoList,
          businessId: this.businessId,
          businessTypeList: this.businessType
        })
      }).then(({ data }) => {
        if(data) {
          this.$message.success('保存成功')
          this.visible = false
        }
      })
    },
    // 放大图片
    enlargeImage (image) {
      this.$refs.bigImg.init(image)
      // this.$alert(`<div class="imgBox"><img src="${image}" alt=""></div>`, '查看大图', {
      //   dangerouslyUseHTMLString: true,
      //   customClass: 'bigImg'
      // })
    }
  }
}
</script>

<style lang="less">
.image-manage-fy {
  .imageArea {
    background: #e6e6e6;
    margin: 0 10px;
    .imageItem {
      display: inline-block;
      width: 120px;
      height: 120px;
      line-height: 120px;
      text-align: left;
      cursor: pointer;
      img {
        vertical-align: middle;
        width: 110px;
        height: 110px;
      }
    }
    .el-dialog__wrapper {
      z-index: 1500 !important;
    }
    .noData {
      width: 100%;
      text-align: left;
      // height: 100px;
      min-height: 100px;
      // line-height: 100px;
      font-size: 22px
    }
  }
  .el-message-box.bigImg {
    max-width: 80%;
    .imgBox {
      max-height: 500px;
      overflow: auto
    }
}
  .el-dialog {
    margin-top: 5vh !important
  }
  .el-dialog__body {
    max-height: 450px;
    overflow: auto;
    padding: 10px 25px;
    padding: 0 10px;
  }
  .el-dialog__footer {
    text-align: right
  }
}
.el-message-box.bigImg {
    max-width: 80%;
    .imgBox {
      max-height: 500px;
      overflow: auto
    }
}
.popImg {
  width: 300px;
  height: 300px;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
